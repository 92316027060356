import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Notifications Redux States
import { GET_ADD_ON } from "./actionType";

import {
  AddOnApiResponseSuccess,
  AddOnApiResponseError
} from "./action";

//Include Both Helper File with needed methods
import { getAddOnApi } from "../../helpers/fakebackend_helper";

function* getAddOn(data) {
  try {
    const response = yield call(getAddOnApi, data.data);
    yield put(AddOnApiResponseSuccess(GET_ADD_ON, response));
  } catch (error) {
    yield put(AddOnApiResponseError(GET_ADD_ON, error));
  }
}

export function* watchGetAddOn() {
  yield takeEvery(GET_ADD_ON, getAddOn);
}

function* AddOnSaga() {
  yield all([fork(watchGetAddOn)]);
}

export default AddOnSaga;
