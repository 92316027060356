import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Profile Redux States
import { GET_PROFILEDETAILS, UPDATE_PHONE,   UPDATE_EMAIL, } from "./actionType";

import {
  ProfileDetailsApiResponseSuccess,
  ProfileDetailsApiResponseError,
  UpdatedPhoneApiResponseSuccess,
  UpdatedPhoneApiResponseError,
  UpdatedEmailApiResponseSuccess,
  UpdatedEmailApiResponseError
} from "./action";

//Include Both Helper File with needed methods
import { getProfileDetailsApi, mobileNumberUpdateApiCall, emailAddressUpdateApiCall } from "../../helpers/fakebackend_helper";

function* getProfileDetails(data) {
  try {
    const response = yield call(getProfileDetailsApi, data.payload);
    localStorage.setItem('STATECODE',response.data[0].STATECODE);
    localStorage.setItem('BRCODE',response.data[0].BRCODE);
    if (response && response.data && response.data[0]?.OOWNCODE?.trim()) {
      localStorage.setItem('client_id', response.data[0].OOWNCODE.trim());
  }
  
  
    yield put(ProfileDetailsApiResponseSuccess(GET_PROFILEDETAILS, response));
  } catch (error) {
    yield put(ProfileDetailsApiResponseError(GET_PROFILEDETAILS, error));
  }
}

export function* watchGetProfileDetails() {
  yield takeEvery(GET_PROFILEDETAILS, getProfileDetails);
}

// Update Mobile
function* mobileNumberUpdate(data) {
  ////console.log(data);
  try {
    const response = yield call(mobileNumberUpdateApiCall, data.data);
    yield put(UpdatedPhoneApiResponseSuccess(UPDATE_PHONE, data));
    toast.success("Mobile number updated successfully", { autoClose: 1500 });
  } catch (error) {
    yield put(UpdatedPhoneApiResponseError(UPDATE_PHONE, error));
    toast.error("Mobile number updated Failed", { autoClose: 1500 });
  }
}

export function* watchUpdateMobile() {
  yield takeEvery(UPDATE_PHONE, mobileNumberUpdate);
}

// Update Email
function* EmailUpdate(data) {
  ////console.log(data);
  try {
    const response = yield call(emailAddressUpdateApiCall, data.data);
    yield put(UpdatedEmailApiResponseSuccess(UPDATE_EMAIL, data));
    toast.success("Email updated successfully", { autoClose: 1500 });
  } catch (error) {
    yield put(UpdatedEmailApiResponseError(UPDATE_EMAIL, error));
    toast.error("Email updated Failed", { autoClose: 1500 });
  }
}

export function* watchUpdateEmail() {
  yield takeEvery(UPDATE_EMAIL, EmailUpdate);
}

function* ProfileDetailsSaga() {
  yield all([
    fork(watchGetProfileDetails),
    fork(watchUpdateMobile),
    fork(watchUpdateEmail),
  ]);
}

export default ProfileDetailsSaga;
