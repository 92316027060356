// import React, { useEffect, useState } from 'react';
// import './Tooltip.css';
// import Loader from '../../Components/Common/Loader';
// import { useLocation } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { toast } from 'react-toastify';
// import axios from 'axios';
// import { DIGIO_FAMILY_SUBMITION, MOD_EKYC_NEW, OPEN_DIGILOKCKER_FAMILY } from '../../helpers/url_helper';
// import Encrypted from '../Dashboard/Encripted';
// import Share1 from "../../Components/CustomComponents/Models/share/Share1";
// import Share from './../../../src/assets/images/user-icon.svg';
// import refresh from './../../../src/assets/images/refresh.svg';
// import closeIcon from './../../../src/assets/images/close.svg';

// const FamilyTable = () => {
//     const location = useLocation();
//     const client_code = localStorage.getItem('client_id');
//     const client_token = localStorage.getItem('client_token');
//     const client_type = localStorage.getItem('client_type');
//     const [accounts, setAccounts] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [openmodal, setOpenmodal] = useState(false);
//     const { profileDetails } = useSelector((state) => ({
//         profileDetails: state.ProfileDetails.userProfile,
//     }));
//     const [data1, setData] = useState([]);
//     const [selectedItems, setSelectedItems] = useState([]);
//     const [selectedMember, setSelectedMember] = useState(null);
//     const HUF = accounts?.filter(rel => rel?.relation === '6');
//     const fourDigit = HUF[0]?.pangir.trim();
//     const fourthCharacter = fourDigit === undefined ? 'P' : fourDigit[3];
//     const selfCount = accounts?.filter(account => account?.relation == 1 || account?.rejection?.relation == 1);
//     const selfCountReject = accounts?.filter(account => account?.rejection?.relation == 1);
//     const spouceCount = accounts?.filter(account => account.relation == 2);
//     const DependentParentCount = accounts?.filter(account => account.relation == 3);
//     const relationOptions = (selfCount?.length > 0 && selfCount[0]?.validate_status == 1) || (selfCountReject?.length > 0) ? [

//         { label: 'Spouse', value: 2 },
//         { label: 'Dependent Parent', value: 3 },
//         { label: 'Dependent Child', value: 4 },
//         { label: 'HUF', value: 5 },
//         { label: 'AOP', value: 6 }
//     ] :
//         [
//             { label: 'Self', value: 1 },

//         ]

//     const relationOptions1 = [
//         { label: 'Self', value: 1 },
//     ]




//     const handleRelationChange = (id, newRelation, member, check) => {


//         const selectedRelation = check == 1 ? relationOptions1 : relationOptions.find(option => option.value === newRelation);
//         if (!selectedRelation) {
//             toast.error('Invalid relation selected');
//             return;
//         }
//         if (newRelation === 1 && (selfCount?.length >= 1 && selfCount[0]?.validate_status == 1)) { // For "Self", ensuring only one "Self" is selected
//             toast.error('Self already selected, please select another relation');
//             return;
//         }
//         if (newRelation === 1) {
//             if (selfCount?.length >= 1) {
//                 const previousSelf = selfCount[0];
//                 if (previousSelf.oowncode !== id) {
//                     setAccounts(prevContacts =>
//                         prevContacts.map(contact =>
//                             contact.oowncode === previousSelf.oowncode ? { ...contact, relation: null } : contact
//                         )
//                     );
//                 }
//             }
//         }
//         if (newRelation === 6 && fourthCharacter === "P") {
//             toast.error("For Relation AOP, Trading and demat account should be non-individual.");
//             setAccounts(prevContacts =>
//                 prevContacts.map(contact =>
//                     contact.oowncode === id ? { ...contact, relation: null } : contact
//                 )
//             );
//             return;
//         }
//         if (newRelation === 2 && spouceCount.length >= 1) {
//             toast.error('Spouse already selected, please select another relation');
//             return;
//         }
//         if (newRelation === 3) {
//             const newMemberDob = new Date(accounts.find(contact => contact.oowncode === id).ddateofbirth);
//             const ageDifference = (new Date(selfCount[0]?.ddateofbirth) - newMemberDob) / (1000 * 3600 * 24 * 365); // Difference in years
//             if (ageDifference < 15) {
//                 toast.error(`Please update correction Relation.`);
//                 return;
//             }
//             if (DependentParentCount.length >= 1) {
//                 toast.error(`Dependent parent already selected please select another relation.`);
//             }
//         }
//         if (newRelation === 2 && (member?.cgender == selfCount[0]?.cgender)) {
//             toast.error('Spouse relation is not  allowed.');
//             return;
//         }
//         if (newRelation === 4) {
//             const newMemberDob = new Date(accounts.find(contact => contact.oowncode === id).ddateofbirth);
//             const ageDifference = (newMemberDob - new Date(selfCount[0]?.ddateofbirth)) / (1000 * 3600 * 24 * 365);
//             if (ageDifference < 15) {
//                 toast.error('For relation "Dependent child", the age difference with "Self" must be at least 15 years.');
//                 return;
//             }
//         }
//         setAccounts(prevContacts =>
//             prevContacts.map(contact =>
//                 contact.oowncode === id ? { ...contact, relation: newRelation } : contact
//             )
//         );
//     };

//     const mobile = profileDetails[0]?.MOBILE.trim().slice(-4);
//     const EMAIL = profileDetails[0]?.EMAIL.trim().slice(8);

//     const handleRemove = (record) => {
//         setOpenmodal(true)
//         setSelectedMember(record)
//         setAccounts(prevData =>
//             prevData.map(item => {
//                 if (item.oowncode === record?.oowncode) {
//                     return {
//                         ...item,
//                         primary: item.primary ? undefined : 'Done'
//                     };
//                 }
//                 return item;
//             })
//         );
//     };

//     const handleValidateAadhar = (item, val) => {
//         if (item?.relation === null && val == 0) {
//             toast.error('Please select a relation');
//             return;
//         } else {
//             const payload = {
//                 f_client_code: item?.oowncode.trim(),
//                 // client_code: client_code.trim(),
//                 client_code: (selfCount[0]?.validate_status == 1 || selfCount[0]?.rejection?.relation == 1) ? selfCount[0]?.oowncode : item?.oowncode,
//                 client_token: client_token,
//                 client_type: client_type,
//                 relation: val == 1 ? 1 : item.relation,
//                 mobile: item?.mobile.trim(),
//                 email: item?.email,
//                 name: item?.fibsacct,
//                 dob: item?.ddateofbirth
//             };

//             const headers = {
//                 'Content-Type': 'application/json',
//             };

//             axios.post(OPEN_DIGILOKCKER_FAMILY, payload, { headers: headers })
//                 .then(res => {
//                     if (res?.status === true) {
//                         setData(item);
//                         setSelectedItems([...selectedItems, item?.oowncode.trim()]);
//                         window.open(res?.link, '_blank');
//                     } else {
//                         toast(res?.message);
//                     }
//                 })
//                 .catch(error => {
//                     toast(error?.message || 'Something went wrong');
//                 });
//         }
//     };

//     const fetchFamilyInfo = async () => {
//         setLoading(true);
//         const payload = {
//             client_code: client_code,
//             mobile: profileDetails[0]?.MOBILE,
//         };

//         try {
//             const response = await axios.post(MOD_EKYC_NEW + '/get-family-info', { 'enc': Encrypted([payload]) });
//             if (response.status) {
//                 const updatedAccounts = response?.data.map(member => ({
//                     ...member,
//                     relation: member.relation,
//                 }));
//                 setAccounts(updatedAccounts);
//                 setLoading(false);
//             } else {
//                 toast('Something went wrong');
//                 setLoading(false);
//                 setAccounts([]);
//             }
//         } catch (err) {
//             toast('Something went wrong');
//             setAccounts([]);
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         setData([]);
//         setLoading(true);
//         if (profileDetails[0]?.MOBILE) {
//             fetchFamilyInfo();
//         }
//     }, [profileDetails[0]?.MOBILE]);

//     const handlesubmit = (record) => {
//         const payload = {
//             client_code:selfCount[0]?.oowncode,
//             client_type: client_type,
//             family_declaration: [{
//                 client_code: selfCount[0]?.oowncode || "",
//                 f_client_code: record.oowncode?.trim() || "",
//                 name: record?.fibsacct,
//                 dob: record.ddateofbirth?.trim() || "",
//                 digio_id: record.digio_id?.trim() || null,
//                 digio_response: record.digio_response?.trim() || null,
//                 validate_status: 0,
//                 relation: null,
//                 email: record.email,
//                 mobile: record.mobile,
//                 status: 3
//             }
//             ]
//         }
//         setLoading(true)
//         axios.post(DIGIO_FAMILY_SUBMITION, payload)
//             .then(res => {
//                 setLoading(false)
//                 if (res.status) {
//                     setOpenmodal(false)
//                     setSelectedMember(null)
//                     fetchFamilyInfo()
//                 } else {
//                     toast.error('something went wrong')
//                 }
//             })
//             .catch(error => {
//                 setLoading(false)
//                 toast(error?.message || 'something went wrong')
//             });
//     }

//     const handlecancle = () => {
//         setOpenmodal(false)
//         setSelectedMember(null)
//     }

//     const handleRelationChange1 = (newRelation) => {
//         setSelectedItems([...selectedItems, newRelation?.trim()]);


//     };

//     if (loading) {
//         return <Loader />;
//     }
//     return (
//         <div className="card-conrainer">
//             {openmodal && (
//                 <div
//                     style={{
//                         position: 'fixed',
//                         top: 0,
//                         left: 0,
//                         right: 0,
//                         bottom: 0,
//                         backgroundColor: 'rgba(0, 0, 0, 0.5)',
//                         display: 'flex',
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                         zIndex: 1000,
//                     }}

//                 >
//                     <div
//                         style={{
//                             backgroundColor: 'white',
//                             padding: '20px',
//                             borderRadius: '5px',
//                             maxWidth: '400px',
//                             width: '100%',
//                             textAlign: 'center',
//                         }}

//                     >
//                         <div
//                             style={{
//                                 marginTop: '10px',
//                             }}
//                         >
//                             <p>Are you sure you want to remove <strong>{selectedMember?.fibsacct}</strong> from your family details</p>
//                             <button
//                                 style={{
//                                     padding: '10px 20px',
//                                     fontSize: '16px',
//                                     cursor: 'pointer',
//                                     border: 'none',
//                                     backgroundColor: 'gray',
//                                     color: 'white',
//                                     borderRadius: '5px',
//                                     marginTop: '20px',
//                                 }}
//                                 onClick={() => { handlecancle() }}
//                             >
//                                 cancel
//                             </button>

//                             <button
//                                 style={{
//                                     padding: '10px 20px',
//                                     fontSize: '16px',
//                                     cursor: 'pointer',
//                                     border: 'none',
//                                     backgroundColor: '#5367FC',
//                                     color: 'white',
//                                     borderRadius: '5px',
//                                     marginTop: '20px',
//                                     marginLeft: '20px'
//                                 }}
//                                 onClick={() => { handlesubmit(selectedMember) }}
//                             >
//                                 Submit
//                             </button>

//                         </div>
//                     </div>
//                 </div>
//             )}
//             <div className="card-row">
//                 <p style={{ color: '#231F20' }} className="breadcrumb-item active main-header-secondary-page mb-4">
//                     The Mobile number: <span style={{ fontWeight: '600' }}>XXXXXX{mobile}</span> and/or email <span style={{ fontWeight: '600' }}>XXXXXXX{EMAIL}</span> you provided are linked to multiple trading accounts. {selfCount[0]?.validate_status == 1 ? 'Please update the relationships as per SEBI guidelines.' : 'Please select your primary (Self) account from the below mentioned profiles as per SEBI guidelines.'}
//                 </p>
//                 {accounts?.map((member) => (
//                     <div className="card-col" key={member.oowncode}>
//                         <div className="relation-cards">
//                             <div className="name-part">
//                                 <div className="left">
//                                     <div className="icon">
//                                         <img src={Share} style={{ height: '20px', width: '20px' }} />
//                                     </div>
//                                     <div>
//                                         <h5>{member?.fibsacct}</h5>
//                                         <p>{member?.oowncode}</p>
//                                     </div>
//                                 </div>
//                                 <div className={member.status === 0 ? 'pending' : member.status === 1 ? 'pending' : member.status === 2 ? 'verified' : member.status === 3 ? 'reject' : (member.status === null && member?.rejection?.reason) ? 'reject' : ''}>

//                                     {/* <p style={{ fontWeight: '600' }}>{(member.status === 0) ? 'Pending validation' : (member.status === 1) ? 'Pending for verification' : member.status === 2 ? 'Verified' : member.status === 3 ? 'Removed' : (member.status === null && member?.rejection?.reason) ? 'Rejected' : ''}</p> */}
//                                     <p style={{ fontWeight: '600' }}>{member?.status_msg}</p>
//                                 </div>
//                             </div>
//                             <div className="pan-dob-part pt-0">
//                                 <div className="pan-box">
//                                     <h5>PAN no:</h5>
//                                     <p style={{ marginLeft: '5px' }}>{member?.pangir}</p>
//                                 </div>
//                                 <div className="pan-box">
//                                     <h5>Date of birth: </h5>
//                                     <p style={{ marginLeft: '5px' }}> {member?.ddateofbirth ? (new Date(member.ddateofbirth).getDate()).toString().padStart(2, '0') + '/' + (new Date(member.ddateofbirth).getMonth() + 1).toString().padStart(2, '0') + '/' + new Date(member.ddateofbirth).getFullYear() : ''}</p>
//                                 </div>
//                             </div>
//                             <div className="relation-part">
//                                 {
//                                     // (selfCount?.length > 0 && (selfCount[0]?.validate_status === 1 || member?.rejection?.relation == 1)) &&
//                                     // member.validate_status == 0 && member.status !== 3 &&
//                                     (member.validate_status == 0 && (selfCount[0]?.validate_status == 1 || selfCountReject.length > 0)) && member.relation !== 1 &&
//                                     member.status !== 3 &&

//                                     <h4>Select your relation</h4>
//                                 }
//                                 <div className="btn-part">
//                                     {
//                                         (selfCount?.length > 0 && selfCount[0]?.validate_status == 1) || (selfCountReject?.length > 0) ?
//                                             ((member.relation == 1 && member.validate_status == 1) || (member.rejection?.relation == 1))
//                                                 ?
//                                                 relationOptions1?.map((option) => (
//                                                     <label key={option.value} className="rdo-btn"
//                                                         style={{
//                                                             opacity: 0.5
//                                                         }}>
//                                                         <input
//                                                             type="radio"
//                                                             name={`radio-${member.oowncode}`}
//                                                             value={option.value}
//                                                             checked={member.relation === option.value}
//                                                             onChange={() => { handleRelationChange(member.oowncode, option.value, member, 1) }}

//                                                         />
//                                                         <div className="checkmark">{option.label}</div>
//                                                     </label>
//                                                 ))
//                                                 :
//                                                 relationOptions?.map((option) => (
//                                                     <label key={option.value} className="rdo-btn"
//                                                         style={{
//                                                             opacity: member?.validate_status === 1 || member.status === 3 || member.status === 1 || (member.status == 0 && member.relation == 1) ? 0.5 : 1
//                                                         }}>
//                                                         <input
//                                                             type="radio"
//                                                             name={`radio-${member.oowncode}`}
//                                                             value={option.value}
//                                                             checked={member.relation === option.value}
//                                                             onChange={() => { handleRelationChange(member.oowncode, option.value, member) }}
//                                                             disabled={member?.validate_status === 1 || member.status == 3 || member.status == 1 || (member.status == 0 && member.relation == 1)}
//                                                         />
//                                                         <div className="checkmark">{option.label}</div>
//                                                     </label>
//                                                 )) :
//                                             null
//                                     }
//                                 </div>
//                             </div>
//                             {member?.rejection?.relation !== 1 && member.validate_status == 0 &&
//                                 (selfCount[0]?.validate_status == 1 || selfCountReject.length > 0) && member.relation !== 1 &&
//                                 member.status !== 3 &&
//                                 // (member.relation !== 1 && selfCount?.length > 0 && (selfCount[0]?.validate_status === 1 || member?.rejection?.relation == 1)) &&
//                                 // member.validate_status == 0 && member.status !== 3 &&

//                                 <div
//                                     style={{
//                                         padding: '5px',
//                                         background: 'rgba(241, 91, 77, 0.1)', // #F15B4D with 10% transparency
//                                         width: '50%',
//                                         textAlign: 'center',
//                                         cursor: member.validate_status == 1 || member?.status == 3 || (member?.rejection?.relation == 1) ? 'not-allowed' : 'pointer', // Change cursor to 'not-allowed' when disabled
//                                         borderRadius: '8px',
//                                         border: '1px solid #F15B4D',
//                                         display: 'flex ',// Border color #F15B4D
//                                         alignItems: 'center'
//                                     }}
//                                     onClick={() => {
//                                         // Only allow the click if the conditions are not met
//                                         if (member?.validate_status != 1 && member?.status != 3) {
//                                             handleRemove(member);
//                                         }
//                                     }}
//                                 >
//                                     <p style={{ color: '#F15B4D', fontSize: '12px', paddingLeft: '6px' }}> Remove from family</p>
//                                     <img
//                                         src={closeIcon}// Replace with the actual path to your SVG icon
//                                         alt="Remove"
//                                         style={{
//                                             width: '13px', // Adjust size as needed
//                                             height: '13px', // Adjust size as needed
//                                             marginLeft: '6px', // Space between the icon and text
//                                             verticalAlign: 'middle',
//                                             color: '#F15B4D',
//                                             // Align the icon vertically with the text
//                                         }}
//                                     />
//                                 </div>
//                             }

//                             {/* {
//                                 ((member.status == null && member?.rejection == null) && member?.primary !== 'Done') &&
//                                 <p class="para">Share link with {member?.fibsacct} to validate her/him identity.</p>

//                             } */}
//                             {
//                                 (member.status == 0 && member?.primary !== 'Done') &&
//                                 <p class="para" style={{ color: '#E5C100' }}>{member?.fibsacct} identity is still pending for validation. Re-share link to validate.</p>

//                             }
//                             {
//                                 (member.status == 1 && member?.primary !== 'Done') &&
//                                 <p class="para" style={{ color: '#E5C100' }}>Change of relation {member?.fibsacct} identity is pending for final verification</p>

//                             }
//                             {
//                                 (member.status == 3 && member?.primary !== 'Done') &&
//                                 <p class="para" style={{ color: '#F15B4D' }}>Your request to remove {member?.fibsacct}from family is pending for approval</p>

//                             }
//                             {
//                                 ((member.status == null && member?.rejection?.reason) && member?.primary !== 'Done') &&
//                                 <p class="para" style={{ color: '#F15B4D' }}>Your change in relation with {member?.fibsacct} is rejected due to {member?.rejection?.reason}</p>

//                             }
//                             {
//                                 (member?.primary == 'Done') &&
//                                 <p class="para">By clicking on submit,{member?.fibsacct}will be remove from your family.</p>

//                             }
//                             {(selfCount?.length > 0 && selfCount[0]?.validate_status === 1) || (selfCountReject?.length > 0) ?
//                                 member?.validate_status === 0 && member?.status !== 3 && !selectedItems?.includes(member?.oowncode.trim()) && (
//                                     <div className="button-part mt-3">
//                                         <button className="btn" onClick={() => handleValidateAadhar(member, 0)}>{member?.rejection !== null && 'Re-'}Validate</button>
//                                         or
//                                         <Share1 newData={member} account={member?.relation} onRelationChange={handleRelationChange1} />
//                                     </div>
//                                 )
//                                 :
//                                 (!selectedItems?.includes(member?.oowncode.trim()))
//                                 &&
//                                 <div className="button-part mt-3">
//                                     <button className="btn" onClick={() => handleValidateAadhar(member, 1)} disabled={accounts.length == 1}>{accounts.length == 1?"Self":'Declare Self'}</button>

//                                 </div>
//                             }
//                             {(selectedItems?.includes(member?.oowncode.trim()) && member.validate_status == 0) &&
//                                 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
//                                     <a
//                                         href="#"
//                                         style={{border: '1px solid #5367fc', fontSize: '14px',fontWeight: 400,color:'#5367fc'}}
//                                         className="default-link btn"
//                                         onClick={(e) => {
//                                             e.preventDefault();
//                                             // fetchFamilyInfo();
//                                             window.location.reload();
//                                         }}
//                                     >
//                                         Refresh to check status
//                                         <img
//                                             src={refresh} // Replace with your actual SVG file path
//                                             alt="Refresh Icon"
//                                             style={{ marginLeft: '7px', width: '13px', height: '13px' }} // Style it as needed
//                                         />
//                                     </a>
//                                 </div>

//                             }

//                         </div>
//                     </div>
//                 ))}
//             </div>
//             <p style={{ textAlign: 'center' }}>{accounts.length == 0 && 'Record not found'}</p>
//             <p style={{ marginTop: '20px', textAlign: 'center', fontWeight: '400', color: '#231F20' }}>{accounts.length == 0 ? null : 'Please complete the process within 15 days; otherwise, all pending accounts will be deactivated.'}</p>
//             <div style={{ height: '20px' }}></div>
//         </div>
//     );
// };

// export default FamilyTable;






import React, { useEffect, useState } from 'react';

import './Tooltip.css';

import Loader from '../../Components/Common/Loader';

import { useLocation, useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';

import axios from 'axios';

import { DIGIO_FAMILY_SUBMITION, GET_PROFILE_NEW, MOD_EKYC_NEW, OPEN_DIGILOKCKER_FAMILY, redirectApp1 } from '../../helpers/url_helper';

import Encrypted from '../Dashboard/Encripted';

import Share1 from "../../Components/CustomComponents/Models/share/Share1";

import Share from './../../../src/assets/images/user-icon.svg';

import refresh from './../../../src/assets/images/refresh.svg';

import closeIcon from './../../../src/assets/images/close.svg';
import mail from '../../../src/assets/images/mail.svg'
import call from '../../../src/assets/images/call(1).svg'

const FamilyTable = () => {
    const navigate = useNavigate();
    const client_code = localStorage.getItem('client_id');
    const client_token = localStorage.getItem('client_token');
    const client_type = localStorage.getItem('client_type');
    const [accounts, setAccounts] = useState([]);
    const [pendingData, setPendingData] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const { availableMargin } = useSelector((state) => ({
        availableMargin: state.MarginOverview,
    }));

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    const [pendingRequest, setPendingRequest] = useState(false);

    const [loading, setLoading] = useState(true);
    const [loading1, setLoading1] = useState(false);
    const [shouldContinueApi, setShouldContinueApi] = useState(false);
    const [openmodal, setOpenmodal] = useState(false);
    const [openfamily, setOpenAddfamily] = useState(false);
    const [selectedRelation, setSelectedRelation] = useState('');
    const [addFamily, setAddfmaily] = useState('');
    const { profileDetails } = useSelector((state) => ({
        profileDetails: state.ProfileDetails.userProfile,
    }));
    const [data1, setData] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedMember, setSelectedMember] = useState(null);

    useEffect(() => {

        let intervalId;

        const callApi = async () => {
            try {

                const response = await axios.post(MOD_EKYC_NEW + '/get-family-member-request', { 'enc': Encrypted([]) });
                if (response?.data[0]?.validate_status == 1) {
                    setPendingData(response?.data)
                    setShouldContinueApi(false)
                }
            } catch (err) {

                toast('Something went wrong');
                setShouldContinueApi(false)
            }

        };

        if (shouldContinueApi) {
            intervalId = setInterval(callApi, 10000); // Call API every 10 seconds
        }

        return () => {
            clearInterval(intervalId); // Cleanup on unmount or condition change
        };
    }, [shouldContinueApi]);


    function convertValue(value) {
        // Check if the value is negative
        if (value < 0) {
            // If negative, convert it to positive
            return Math.abs(value);
        } else {
            // If positive, convert it to negative
            return -value;
        }
    }
    let convertedValue = convertValue(availableMargin?.availableMargin[0]?.Final1);
    const HUF = accounts?.filter(rel => rel?.relation === '6');

    const fourDigit = HUF[0]?.pangir.trim();

    const fourthCharacter = fourDigit === undefined ? 'P' : fourDigit[3];

    const selfCount = accounts?.filter(account => account?.relation == 1 || account?.rejection?.relation == 1);


    const ceckDesign = selfCount[0]?.oowncode?.trim() == profileDetails[0]?.OOWNCODE?.trim()
    const selfCountReject = accounts?.filter(account => account?.rejection?.relation == 1);
    const spouceCount = accounts?.filter(account => account.relation == 2);
    const DependentParentCount = accounts?.filter(account => account.relation == 3);
    const relationOptions = (selfCount?.length > 0 && selfCount[0]?.validate_status == 1) || (selfCountReject?.length > 0) ? [
        { label: 'Spouse', value: 2 },

        { label: 'Dependent Parent', value: 3 },

        { label: 'Dependent Child', value: 4 },

        { label: 'HUF', value: 5 },

        { label: 'AOP', value: 6 }

    ] :

        [

            { label: 'Self', value: 1 },
        ]

    const relationOptions1 = [

        { label: 'Self', value: 1 },

    ]

    const handleRelationClick = (relation) => {
        if (addFamily?.length == 0) {
            return toast.error('Please enter client code')
        }
        setSelectedRelation(relation);
    };

    const handleRelationChange = (id, newRelation, member, check) => {
        const selectedRelation = check == 1 ? relationOptions1 : relationOptions.find(option => option.value === newRelation);
        if (!selectedRelation) {
            toast.error('Invalid relation selected');
            return;
        }

        if (newRelation === 1 && (selfCount?.length >= 1 && selfCount[0]?.validate_status == 1)) { // For "Self", ensuring only one "Self" is selected

            toast.error('Self already selected, please select another relation');

            return;

        }

        if (newRelation === 1) {

            if (selfCount?.length >= 1) {

                const previousSelf = selfCount[0];

                if (previousSelf.oowncode !== id) {

                    setAccounts(prevContacts =>

                        prevContacts.map(contact =>

                            contact.oowncode === previousSelf.oowncode ? { ...contact, relation: null } : contact

                        )

                    );

                }

            }

        }

        if (newRelation === 6 && fourthCharacter === "P") {

            toast.error("Trading and Demat account must belong to non-individual entities for 'AOP' relation.");

            setAccounts(prevContacts =>

                prevContacts.map(contact =>

                    contact.oowncode === id ? { ...contact, relation: null } : contact

                )

            );

            return;

        }

        if (newRelation === 2 && spouceCount.length >= 1) {

            toast.error('Spouse relation already selected. Choose a different relation.');

            return;

        }

        if (newRelation === 3) {

            const newMemberDob = new Date(accounts.find(contact => contact.oowncode === id).ddateofbirth);

            const ageDifference = (new Date(selfCount[0]?.ddateofbirth) - newMemberDob) / (1000 * 3600 * 24 * 365); // Difference in years

            if (ageDifference < 15) {

                toast.error(`Cannot select 'Dependent Parent' as the age difference is less than 15 years.`);

                return;

            }

            if (DependentParentCount.length >= 1) {

                toast.error(`Dependent Parent already selected. Choose a different relation.`);

            }

        }

        if (newRelation === 2 && (member?.cgender == selfCount[0]?.cgender)) {

            toast.error('This person cannot be selected as a ‘Spouse’. Please choose another person.');

            return;

        }

        if (newRelation === 4) {

            const newMemberDob = new Date(accounts.find(contact => contact.oowncode === id).ddateofbirth);

            const ageDifference = (newMemberDob - new Date(selfCount[0]?.ddateofbirth)) / (1000 * 3600 * 24 * 365);

            if (ageDifference < 15) {

                toast.error('Cannot select Dependent Child as the age difference is less than 15 years.');

                return;

            }

        }

        setAccounts(prevContacts =>

            prevContacts.map(contact =>

                contact.oowncode === id ? { ...contact, relation: newRelation } : contact

            )

        );

    };



    const mobile = profileDetails[0]?.MOBILE.trim().slice(-4);

    const EMAIL = profileDetails[0]?.EMAIL.trim().slice(8);
    const INCOME = profileDetails[0]?.NINCOME.trim();

    const handclickAddfamily = async () => {
        if (!isChecked) {
            return toast.error('Select the checkbox to proceed')
        }
        if (INCOME <= 0) {
            navigate('/profile')
            // return toast.error('To complete your requested modification, we need your most recent annual income information. Please update your profile with your current income details. Once your income is verified and updated in your account, you can proceed with your modification.')
        }
        if (convertedValue < 25) {
            const payload = {
                ClientCode: client_code,
                amount: convertedValue > 0 ? (25 - convertedValue) : (Math.abs(convertedValue) + 25),
                redirectUrl: redirectApp1,
            };
            const encryptedPayload = Encrypted([payload]);
            return window.location.href = 'https://payment.bigul.co/v1/?enc=' + encryptedPayload;
        }
        setLoading1(true)
        try {
            const response = await axios.post(MOD_EKYC_NEW + '/family-esign-my-etrade', { 'enc': Encrypted([]) });
            if (response.status) {
                setLoading1(false);
                setShouldContinueApi(true)

                window.open(response?.redirectUrl, '_blank');
            }
            else {
                setLoading1(false)
                toast.error('something went wrong')
            }
        } catch (err) {

            toast('Something went wrong');
            setLoading1(false);

        }
    }
    const handclickAddfamilyRemove = async () => {

        setLoading1(true)
        try {

            const response = await axios.post(MOD_EKYC_NEW + '/remove-family-member-request', { 'enc': Encrypted([]) });

            if (response.status) {
                setLoading1(false);
                window.location.reload();
            }
            else {
                setLoading1(false)
                toast.error('something went wrong')
            }
        } catch (err) {

            toast('Something went wrong');
            setLoading1(false);

        }

    }
    const handleRemove = (record) => {

        setOpenmodal(true)

        setSelectedMember(record)

        setAccounts(prevData =>

            prevData.map(item => {

                if (item.oowncode === record?.oowncode) {

                    return {

                        ...item,

                        primary: item.primary ? undefined : 'Done'

                    };

                }

                return item;

            })

        );

    };



    const handleValidateAadhar = (item, val) => {

        if (item?.relation === null && val == 0) {

            toast.error('Please select a relation');

            return;

        } else {

            const payload = {

                f_client_code: item?.oowncode.trim(),

                // client_code: client_code.trim(),

                client_code: (selfCount[0]?.validate_status == 1 || selfCount[0]?.rejection?.relation == 1) ? selfCount[0]?.oowncode : item?.oowncode,

                client_token: client_token,

                client_type: client_type,

                relation: val == 1 ? 1 : item.relation,

                mobile: item?.mobile.trim(),

                email: item?.email,

                name: item?.fibsacct,

                dob: item?.ddateofbirth

            };



            const headers = {

                'Content-Type': 'application/json',

            };



            axios.post(OPEN_DIGILOKCKER_FAMILY, payload, { headers: headers })

                .then(res => {

                    if (res?.status === true) {

                        setData(item);

                        setSelectedItems([...selectedItems, item?.oowncode.trim()]);

                        window.open(res?.link, '_blank');

                    } else {

                        toast(res?.message);

                    }

                })

                .catch(error => {

                    toast(error?.message || 'Something went wrong');

                });

        }

    };

    const fetchFamilyInfo1 = async () => {
        setPendingRequest(false)
        setLoading(true);

        const payload = {

            client_code: client_code,

            mobile: profileDetails[0]?.MOBILE,

        };



        try {

            const response = await axios.post(MOD_EKYC_NEW + '/get-family-info', { 'enc': Encrypted([payload]) });



            if (response.status) {

                const updatedAccounts = response?.data.map(member => ({

                    ...member,

                    relation: member.relation,

                }));

                setAccounts(updatedAccounts);
                try {

                    const response = await axios.post(MOD_EKYC_NEW + '/get-family-member-request', { 'enc': Encrypted([]) });
                    if (response.status) {
                        setLoading(false);
                        if (response?.data[0]?.client_code == client_code?.trim()) {
                            setPendingData(response?.data)
                        }
                        else {
                            setPendingData(response?.data)
                            setPendingRequest(true)
                        }
                    }
                    else {
                        setLoading(false);
                        setPendingData([])

                    }
                } catch (err) {

                    toast('Something went wrong');
                    setLoading(false);

                }




            } else {

                toast('Something went wrong');

                setLoading(false);

                setAccounts([]);

            }

        } catch (err) {

            toast('Something went wrong');

            setAccounts([]);

            setLoading(false);

        }

    };

    const fetchFamilyInfo = async () => {

        setLoading(true);

        const payload = {

            client_code: client_code,

            mobile: profileDetails[0]?.MOBILE,

        };



        try {

            const response = await axios.post(MOD_EKYC_NEW + '/get-family-info', { 'enc': Encrypted([payload]) });



            if (response.status) {

                const updatedAccounts = response?.data.map(member => ({

                    ...member,

                    relation: member.relation,

                }));

                setAccounts(updatedAccounts);

                setLoading(false);





            } else {

                toast('Something went wrong');

                setLoading(false);

                setAccounts([]);

            }

        } catch (err) {

            toast('Something went wrong');

            setAccounts([]);

            setLoading(false);

        }

    };



    useEffect(() => {
        setData([]);
        setLoading(true);
        setShouldContinueApi(false)

        if (profileDetails[0]?.MOBILE) {
            fetchFamilyInfo1();
        }

    }, [profileDetails[0]?.MOBILE]);





    const handlesubmit = (record) => {

        const payload = {

            client_code: selfCount[0]?.oowncode,

            client_type: client_type,

            family_declaration: [{

                client_code: selfCount[0]?.oowncode || "",

                f_client_code: record.oowncode?.trim() || "",

                name: record?.fibsacct,

                dob: record.ddateofbirth?.trim() || "",

                digio_id: record.digio_id?.trim() || null,

                digio_response: record.digio_response?.trim() || null,

                validate_status: 0,

                relation: null,

                email: record.email,

                mobile: record.mobile,

                status: 3

            }

            ]

        }

        setLoading(true)

        axios.post(DIGIO_FAMILY_SUBMITION, payload)

            .then(res => {

                setLoading(false)

                if (res.status) {

                    setOpenmodal(false)

                    setSelectedMember(null)

                    fetchFamilyInfo()

                } else {

                    toast.error('something went wrong')

                }

            })

            .catch(error => {
                setLoading(false)
                toast(error?.message || 'something went wrong')

            });

    }



    const handlecancle = () => {
        setOpenmodal(false)
        setSelectedMember(null)

    }

    const handleaddFamilyMember = async () => {
        const check = pendingData?.filter((item) => item?.relation == 2)
        if (addFamily?.length == 0) {
            return toast.error('Please enter client code')
        }
        if (selectedRelation == '') {
            return toast.error('Please select relation')
        }
        if (addFamily == profileDetails[0]?.OOWNCODE?.trim()) {
            return toast.error('Cannot add yourself or a family member with the same client code.')
        }
        const payload1 = {
            'code': addFamily
        }
        setLoading(true)
        try {
            const response = await axios.get(GET_PROFILE_NEW + Encrypted([payload1]));
            if (response?.status) {
                // setLoading(false)
                const data = Array.isArray(response?.data) ? response?.data[0] : response?.data;
                const self = accounts?.length == 1 ? accounts : selfCount

                const newMemberDob = new Date(self[0].ddateofbirth);
                const ageDifference = (newMemberDob - new Date(data?.DDATEOFBIRTH?.date)) / (1000 * 3600 * 24 * 365); // Difference in years
                const pan = data?.PANGIR?.trim()
                var fourthDigit = pan?.charAt(3);
                if (selectedRelation == 'Spouse') {
                    if (spouceCount[0]?.validate_status == 1 || check?.length > 0) {
                        setLoading(false)
                        return toast.error('Spouse already selected please select another relation ')
                    }
                    if (self[0]?.cgender == data?.CGENDER) {
                        setLoading(false)
                        return toast.error('Spouse selection not allowed due to gender mismatch.')
                    }

                }
                if (selectedRelation == 'Dependent parent') {
                    if (ageDifference < 15) {
                        toast.error(`Cannot select 'Dependent Parent' as the age difference is more than 15 years.`);
                        setLoading(false)
                        return;
                    }

                }
                if (selectedRelation == 'Dependent child') {
                    if (ageDifference > -15) {
                        toast.error('Cannot select Dependent Child as the age difference is less than 15 years.');
                        setLoading(false)
                        return;
                    }

                }
                if (selectedRelation == 'AOP') {
                    if (fourthDigit == 'P') {
                        toast.error('Trading and Demat account must belong to non-individual entities for AOP relation.');
                        setLoading(false)
                        return;
                    }

                }

                const payload = {
                    "client_code": client_code,
                    "f_client_code": addFamily,
                    "client_type": client_type,
                    "mobile": profileDetails[0]?.MOBILE.trim(),
                    "email": profileDetails[0]?.EMAIL.trim(),
                    'pan': profileDetails[0]?.PANGIR.trim(),
                    "relation": selectedRelation == 'Spouse' ? 2 : selectedRelation == 'Dependent parent' ? 3 : selectedRelation == 'Dependent child' ? 4 : selectedRelation == 'HUF' ? 5 : 6
                }

                try {
                    const response = await axios.post(MOD_EKYC_NEW + '/add-family-member', { 'enc': Encrypted([payload]) });

                    if (response?.status) {
                        toast.success(response?.message)
                        setOpenAddfamily(false)
                        fetchFamilyInfo1()
                        setSelectedRelation('')
                        setAddfmaily('')
                        setLoading(false)
                    }
                    else {
                        toast.error(response?.message)
                        setLoading(false)
                    }
                } catch (err) {
                    toast.error('something went wrong')
                    setLoading(false)
                }
            }
            else {
                toast.error(response?.message)
                setLoading(false)
            }

        } catch (err) {
            toast.error(err?.message || 'something went wrong')
            setLoading(false)

        }

    }

    const handlecancelFamily = () => {
        setOpenAddfamily(false)
        setSelectedRelation('')
        setAddfmaily('')
    }

    const handleRelationChange1 = (newRelation) => {
        setSelectedItems([...selectedItems, newRelation?.trim()]);
    };

    if (loading) {
        return <Loader />;
    }

    return (

        <>
            {
                !pendingRequest ?
                    <div className="card-conrainer">
                        {
                            openfamily &&
                            <div style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: 1000,

                            }}
                            >

                                <div
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '20px',
                                        borderRadius: '5px',
                                        maxWidth: '550px',
                                        width: '100%',
                                    }}
                                >
                                    <div style={{ marginTop: '10px' }} >
                                        <h4 style={{ color: '#231F20' }}>Add New Family Member</h4>

                                        {/* <h5 className="text-11 mb-0 mt-2" style={{ color: '#999999' }}>Demo text shsjs shshshs sshshs h </h5> */}

                                        <div className='mt-3'>

                                            <p className="mb-0 mt-2" style={{ color: '#231F20' }}>Client Code of the family member </p>

                                            <div className="text-field-container">

                                                <input type="text"

                                                    placeholder="Enter here"

                                                    className="text-field"

                                                    onChange={(e) => { setAddfmaily(e.target.value) }}

                                                    value={addFamily?.toUpperCase()} />

                                            </div>

                                        </div>

                                        <p style={{ marginTop: '10px', color: '#231F20', fontWeight: 400 }}>Select your relation with the family member </p>

                                        <div className="container1">

                                            {['Spouse', 'Dependent parent', 'Dependent child', 'HUF', 'AOP'].map((relation) => (

                                                <div

                                                    key={relation}

                                                    className={`box family ${selectedRelation === relation ? 'selected' : ''}`}

                                                    onClick={() => handleRelationClick(relation)} // Handle click to set the relation

                                                    style={{
                                                        cursor: 'pointer',
                                                        backgroundColor: selectedRelation === relation ? '#5367FC' : 'transparent',
                                                        color: selectedRelation === relation ? 'white' : '#231F20'
                                                    }} >
                                                    {relation}

                                                </div>

                                            ))}



                                        </div>

                                        <button

                                            style={{
                                                padding: '10px 20px',
                                                fontSize: '16px',
                                                cursor: 'pointer',
                                                border: 'none',
                                                backgroundColor: 'gray',
                                                color: 'white',
                                                borderRadius: '5px',
                                                marginTop: '20px',
                                            }}

                                            onClick={() => { handlecancelFamily() }}

                                        >

                                            Cancel

                                        </button>
                                        <button

                                            style={{

                                                padding: '10px 20px',
                                                fontSize: '16px',
                                                cursor: 'pointer',
                                                border: 'none',
                                                backgroundColor: '#5367FC',
                                                color: 'white',
                                                borderRadius: '5px',
                                                marginTop: '20px',
                                                marginLeft: '20px',
                                                width: '100px'

                                            }}

                                            onClick={() => { handleaddFamilyMember() }}

                                        >

                                            Add

                                        </button>
                                    </div>
                                </div>
                            </div>
                        }

                        {openmodal && (



                            <div

                                style={{

                                    position: 'fixed',

                                    top: 0,

                                    left: 0,

                                    right: 0,

                                    bottom: 0,

                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',

                                    display: 'flex',

                                    justifyContent: 'center',

                                    alignItems: 'center',

                                    zIndex: 1000,

                                }}



                            >

                                <div

                                    style={{

                                        backgroundColor: 'white',

                                        padding: '20px',

                                        borderRadius: '5px',

                                        maxWidth: '400px',

                                        width: '100%',

                                        textAlign: 'center',

                                    }}



                                >

                                    <div

                                        style={{

                                            marginTop: '10px',

                                        }}

                                    >

                                        <p>Are you sure you want to remove <strong>{selectedMember?.fibsacct}</strong> from your family details</p>

                                        <button

                                            style={{

                                                padding: '10px 20px',

                                                fontSize: '16px',

                                                cursor: 'pointer',

                                                border: 'none',

                                                backgroundColor: 'gray',

                                                color: 'white',

                                                borderRadius: '5px',

                                                marginTop: '20px',

                                            }}

                                            onClick={() => { handlecancle() }}

                                        >

                                            Cancel

                                        </button>



                                        <button

                                            style={{

                                                padding: '10px 20px',

                                                fontSize: '16px',

                                                cursor: 'pointer',

                                                border: 'none',

                                                backgroundColor: '#5367FC',

                                                color: 'white',

                                                borderRadius: '5px',

                                                marginTop: '20px',

                                                marginLeft: '20px'

                                            }}

                                            onClick={() => { handlesubmit(selectedMember) }}

                                        >

                                            Submit

                                        </button>
                                    </div>

                                </div>
                            </div>

                        )}

                        <div className="card-row">

                            <div className='d-flex ' style={{ width: '100%', justifyContent: 'space-between' }}>

                                {
                                    accounts?.length == 1 ?
                                        <p style={{}}></p>
                                        // <p>Add a family member to your account using the same mobile number and email address.</p>
                                        :
                                        <p style={{ color: '#231F20', width: '90%' }} className="breadcrumb-item active main-header-secondary-page mb-4">
                                            The Mobile number: <span style={{ fontWeight: '600' }}>XXXXXX{mobile}</span> and/or email <span style={{ fontWeight: '600' }}>XXXXXXX{EMAIL}</span> you provided are linked to multiple trading accounts. {selfCount[0]?.validate_status == 1 ? 'Please update the relationships as per SEBI guidelines.' : 'Please select your primary (Self) account from the below mentioned profiles as per SEBI guidelines.'} </p>
                                }

                                {
                                    (accounts?.length == 1 || (ceckDesign && selfCount[0]?.validate_status == 1)) &&
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-sm text-11 nominee-page-button-save"
                                        onClick={() =>
                                            setOpenAddfamily(true)
                                        } >
                                        + Add family member

                                    </button>
                                }


                            </div>

                            {accounts?.map((member) => {

                                if (accounts.length == 1 && accounts[0]?.oowncode?.trim() == profileDetails[0]?.OOWNCODE?.trim() && pendingData.length == 0) {
                                    return <div style={{ flex: 1, textAlign: 'center' }}>
                                        <p style={{
                                            flex: 1,
                                            textAlign: 'center',
                                            marginTop: '15%'
                                        }}>
                                            I do not found any family member associated with your account , If you wish to add please Click on "Add family member".
                                        </p>
                                    </div>
                                }
                                return <div className="card-col" key={member.oowncode}>

                                    <div className="relation-cards">

                                        <div className="name-part">

                                            <div className="left">

                                                <div className="icon">

                                                    <img src={Share} style={{ height: '20px', width: '20px' }} />

                                                </div>

                                                <div>

                                                    <h5>{member?.fibsacct}</h5>

                                                    <p>{member?.oowncode}</p>

                                                </div>

                                            </div>

                                            <div className={member.status === 0 ? 'pending' : member.status === 1 ? 'pending' : member.status === 2 ? 'verified' : member.status === 3 ? 'reject' : (member.status === null && member?.rejection?.reason) ? 'reject' : ''}>



                                                {/* <p style={{ fontWeight: '600' }}>{(member.status === 0) ? 'Pending validation' : (member.status === 1) ? 'Pending for verification' : member.status === 2 ? 'Verified' : member.status === 3 ? 'Removed' : (member.status === null && member?.rejection?.reason) ? 'Rejected' : ''}</p> */}

                                                <p style={{ fontWeight: '600' }}>{member?.status_msg}</p>

                                            </div>

                                        </div>

                                        <div className="pan-dob-part pt-0 d-sm-flex d-block">

                                            <div className="pan-box mb-2 mb-sm-0">

                                                <h5>PAN no:</h5>

                                                <p style={{ marginLeft: '5px' }}>{member?.pangir}</p>

                                            </div>

                                            <div className="pan-box">

                                                <h5>Date of birth: </h5>

                                                <p style={{ marginLeft: '5px' }}> {member?.ddateofbirth ? (new Date(member.ddateofbirth).getDate()).toString().padStart(2, '0') + '/' + (new Date(member.ddateofbirth).getMonth() + 1).toString().padStart(2, '0') + '/' + new Date(member.ddateofbirth).getFullYear() : ''}</p>
                                            </div>
                                        </div>

                                        <div className="relation-part">

                                            {(ceckDesign) &&
                                                (member.validate_status == 0 && (selfCount[0]?.validate_status == 1 || selfCountReject.length > 0)) && member.relation !== 1 &&
                                                member.status !== 3 &&
                                                <h4>Select your relation</h4>

                                            }
                                            <div className="btn-part">
                                                {(selfCount?.length > 0 && selfCount[0]?.validate_status == 1) || (selfCountReject?.length > 0) ?

                                                    ((member.relation == 1 && member.validate_status == 1) || (member.rejection?.relation == 1)) ?

                                                        // Add your additional condition here
                                                        ((ceckDesign) ?
                                                            (
                                                                relationOptions1?.map((option) => (
                                                                    <label key={option.value} className="rdo-btn"
                                                                        style={{
                                                                            opacity: 0.5
                                                                        }}>
                                                                        <input
                                                                            type="radio"
                                                                            name={`radio-${member.oowncode}`}
                                                                            value={option.value}
                                                                            checked={member.relation === option.value}
                                                                            onChange={() => { handleRelationChange(member.oowncode, option.value, member, 1) }}
                                                                        />
                                                                        <div className="checkmark">{option.label}</div>
                                                                    </label>
                                                                ))
                                                            )

                                                            : (
                                                                relationOptions1?.map((option) => (
                                                                    <label key={option.value} className="rdo-btn"
                                                                        style={{
                                                                            opacity: 0.5
                                                                        }}>
                                                                        <input
                                                                            type="radio"
                                                                            name={`radio-${member.oowncode}`}
                                                                            value={option.value}
                                                                            checked={member.relation === option.value}
                                                                            onChange={() => { handleRelationChange(member.oowncode, option.value, member, 1) }}
                                                                        />
                                                                        <div className="checkmark">{option.label}</div>
                                                                    </label>
                                                                ))
                                                            ))

                                                        : (ceckDesign) &&
                                                        relationOptions?.map((option) => (
                                                            <label key={option.value} className="rdo-btn"
                                                                style={{
                                                                    opacity: member?.validate_status === 1 || member.status === 3 || member.status === 1 || (member.status == 0 && member.relation == 1) ? 0.5 : 1
                                                                }}>
                                                                <input
                                                                    type="radio"
                                                                    name={`radio-${member.oowncode}`}
                                                                    value={option.value}
                                                                    checked={member.relation === option.value}
                                                                    onChange={() => { handleRelationChange(member.oowncode, option.value, member) }}
                                                                    disabled={member?.validate_status === 1 || member.status == 3 || member.status == 1 || (member.status == 0 && member.relation == 1)}
                                                                />
                                                                <div className="checkmark">{option.label}</div>
                                                            </label>
                                                        ))

                                                    : null
                                                }
                                            </div>
                                            {
                                                (member.relation !== 1 && member?.validate_status == 1 && !ceckDesign) &&
                                                <div className="btn-part">
                                                    <label className="rdo-btn" style={{ opacity: 0.5 }}>
                                                        <input
                                                            type="radio"
                                                            // name="radio-DUET0AJ22"
                                                            disabled={true}

                                                            checked={true} // or use a state to control it
                                                        />
                                                        <div className="checkmark">{member?.relation == 1 ? 'Self' : member?.relation == 2 ? 'Spouse' : member?.relation == 3 ? 'Dependent Parent' : member?.relation == 4 ? 'Dependent Child' : member?.relation == 5 ? 'HUF' : 'AOP'}</div>
                                                    </label>
                                                </div>

                                            }


                                        </div>

                                        {(ceckDesign) && member?.rejection?.relation !== 1 && member.validate_status == 0 &&

                                            (selfCount[0]?.validate_status == 1 || selfCountReject.length > 0) && member.relation !== 1 &&

                                            member.status !== 3 &&

                                            // (member.relation !== 1 && selfCount?.length > 0 && (selfCount[0]?.validate_status === 1 || member?.rejection?.relation == 1)) &&

                                            // member.validate_status == 0 && member.status !== 3 &&



                                            <div

                                                style={{

                                                    padding: '5px',

                                                    background: 'rgba(241, 91, 77, 0.1)', // #F15B4D with 10% transparency

                                                    width: '70%',

                                                    textAlign: 'center',

                                                    cursor: member.validate_status == 1 || member?.status == 3 || (member?.rejection?.relation == 1) ? 'not-allowed' : 'pointer', // Change cursor to 'not-allowed' when disabled

                                                    borderRadius: '8px',

                                                    border: '1px solid #F15B4D',

                                                    display: 'flex ',// Border color #F15B4D

                                                    alignItems: 'center',
                                                    justifyContent: 'center'

                                                }}

                                                onClick={() => {

                                                    // Only allow the click if the conditions are not met

                                                    if (member?.validate_status != 1 && member?.status != 3) {

                                                        handleRemove(member);

                                                    }

                                                }}

                                            >

                                                <p style={{ color: '#F15B4D', fontSize: '12px', paddingLeft: '6px' }}> Remove from family</p>

                                                <img

                                                    src={closeIcon}// Replace with the actual path to your SVG icon

                                                    alt="Remove"

                                                    style={{

                                                        width: '13px', // Adjust size as needed

                                                        height: '13px', // Adjust size as needed

                                                        marginLeft: '6px', // Space between the icon and text

                                                        verticalAlign: 'middle',

                                                        color: '#F15B4D',

                                                        // Align the icon vertically with the text

                                                    }}

                                                />

                                            </div>

                                        }

                                        {(member.status == 0 && member?.primary !== 'Done') &&

                                            <p class="para" style={{ color: '#E5C100' }}>{member?.fibsacct} identity is still pending for validation. Re-share link to validate.</p>
                                        }

                                        {

                                            (member.status == 1 && member?.primary !== 'Done') &&

                                            <p class="para" style={{ color: '#E5C100' }}>Change of relation {member?.fibsacct} identity is pending for final verification</p>
                                        }

                                        {(member.status == 3 && member?.primary !== 'Done') &&

                                            <p class="para" style={{ color: '#F15B4D' }}>Your request to remove {member?.fibsacct}from family is pending for approval</p>
                                        }

                                        {((member.status == null && member?.rejection?.reason) && member?.primary !== 'Done') &&

                                            <p class="para" style={{ color: '#F15B4D' }}>Your change in relation with {member?.fibsacct} is rejected due to {member?.rejection?.reason}</p>
                                        }

                                        {(member?.primary == 'Done') &&

                                            <p class="para">By clicking on submit,{member?.fibsacct}will be remove from your family.</p>
                                        }

                                        {(selfCount?.length > 0 && selfCount[0]?.validate_status === 1) || (selfCountReject?.length > 0) ?

                                            member?.validate_status === 0 && member?.status !== 3 && !selectedItems?.includes(member?.oowncode.trim()) && (
                                                ceckDesign ?
                                                    <div className="button-part mt-3">

                                                        <button className="btn" onClick={() => handleValidateAadhar(member, 0)}>{member?.rejection !== null && 'Re-'}Validate</button>

                                                        or

                                                        <Share1 newData={member} account={member?.relation} onRelationChange={handleRelationChange1} />

                                                    </div>
                                                    : null

                                            )

                                            :

                                            (!selectedItems?.includes(member?.oowncode.trim()))

                                            &&

                                            <div className="button-part mt-3">

                                                <button className="btn" onClick={() => handleValidateAadhar(member, 1)} disabled={accounts.length == 1}>{accounts.length == 1 ? "Self" : 'Declare Self'}</button>

                                            </div>
                                        }

                                        {(selectedItems?.includes(member?.oowncode.trim()) && member.validate_status == 0) &&

                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>

                                                <a

                                                    href="#"

                                                    style={{ border: '1px solid #5367fc', fontSize: '14px', fontWeight: 400, color: '#5367fc' }}

                                                    className="default-link btn"

                                                    onClick={(e) => {

                                                        e.preventDefault();

                                                        // fetchFamilyInfo();

                                                        window.location.reload();

                                                    }}

                                                >

                                                    Refresh to check status

                                                    <img

                                                        src={refresh} // Replace with your actual SVG file path

                                                        alt="Refresh Icon"

                                                        style={{ marginLeft: '7px', width: '13px', height: '13px' }} // Style it as needed

                                                    />

                                                </a>

                                            </div>



                                        }
                                    </div>

                                </div>


                            })}
                            {pendingData?.length > 0 &&
                                pendingData.map((item) => (
                                    <div className="card-col" >

                                        <div className="relation-cards">

                                            <div className="name-part">

                                                <div className="left">

                                                    <div className="icon">

                                                        <img src={Share} style={{ height: '20px', width: '20px' }} />

                                                    </div>

                                                    <div>

                                                        <h5>{item?.name}</h5>

                                                        <p>{item?.f_client_code}</p>

                                                    </div>

                                                </div>

                                                <div className={item.status === 0 ? 'pending' : item.status === 1 ? 'pending' : item.status === 2 ? 'verified' : item.status === 4 ? 'reject' : (item.status === null && item?.rejection?.reason) ? 'reject' : ''}>
                                                    <p style={{ fontWeight: '600' }}>{item?.status_msg}</p>
                                                </div>
                                            </div>

                                            <div className="pan-dob-part pt-0 d-sm-flex d-block">

                                                <div className="pan-box mb-2 mb-sm-0">

                                                    <h5>PAN no:</h5>

                                                    <p style={{ marginLeft: '5px' }}>{item?.pan}</p>

                                                </div>
                                                <div className="pan-box">
                                                    <h5>Date of birth: </h5>
                                                    <p style={{ marginLeft: '5px' }}> {item?.dob ? (new Date(item.dob).getDate()).toString().padStart(2, '0') + '/' + (new Date(item.dob).getMonth() + 1).toString().padStart(2, '0') + '/' + new Date(item.dob).getFullYear() : ''}</p>

                                                </div>

                                            </div>
                                            {
                                                item?.relation !== null &&
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-sm text-11 nominee-page-button-save"
                                                    style={{ marginTop: '20px', marginBottom: '20px' }}
                                                    //   onClick={() => handclickAddfamily()}
                                                    disabled={true}

                                                >
                                                    {(item?.relation == '2' ? 'Spouse' :
                                                        item?.relation == '3' ? 'Dependent Parent' :
                                                            item?.relation == '4' ? 'Dependent Child' :
                                                                item?.relation == '5' ? 'HUF' : 'AOP')}
                                                </button>
                                            }
                                            {item?.status == 0 &&

                                                <p class="para" style={{ color: '#E5C100' }}>{item?.name} identity is still pending for validation. Re-share link to validate.</p>
                                            }
                                            {item?.status == 1 &&

                                                <p class="para" style={{ color: '#E5C100' }}>Change of relation {item?.name} identity is pending for final verification</p>
                                            }
                                            {
                                                item?.status == 4 &&
                                                <p class="para" style={{ color: '#F15B4D' }}>Your change in relation  is rejected due to {item?.rejection?.reason}</p>

                                            }
                                        </div>

                                    </div>
                                ))

                            }

                        </div>

                        <p style={{ textAlign: 'center' }}>{accounts.length == 0 && 'Record not found'}</p>

                        {/* <p style={{ marginTop: '20px', textAlign: 'center', fontWeight: '400', color: '#231F20' }}>{accounts.length == 0 ? null : 'Please complete the process within 15 days; otherwise, all pending accounts will be deactivated.'}</p> */}

                        <div style={{ height: '20px' }}></div>
                    </div>
                    :
                    <div className="card-conrainer">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '10px' }}>
                            <p style={{ color: '#231F20', width: '100%', }} className="breadcrumb-item active main-header-secondary-page mb-4">
                                {pendingData[0]?.request_by + ' ' + 'has requested to add you as a' + ' ' +
                                    (pendingData[0]?.relation == '2' ? 'Spouse' :
                                        pendingData[0]?.relation == '3' ? 'Dependent Parent' :
                                            pendingData[0]?.relation == '4' ? 'Dependent Child' :
                                                pendingData[0]?.relation == '5' ? 'HUF' : 'AOP') + '. ' +
                                    'Approval of this request will update your account with her/his details.'}
                            </p>
                            <p style={{ backgroundColor: 'rgba(0, 181, 137, 0.1)', textAlign: 'center', padding: '3px', borderRadius: '10px', marginBottom: '20px', color: pendingData[0]?.status == 4 ? 'red' : pendingData[0]?.status_msg == 'Verified' ? 'green' : 'rgb(229, 193, 0)' }}>{pendingData[0]?.status_msg}</p>
                        </div>
                        <p style={{ color: '#231F20' }}>your current and new details are listed below:</p>
                        <p style={{ fontWeight: '600', marginTop: '20px', color: '#231F20' }}>Current contact details</p>
                        <div className='mt-3 d-flex' style={{ gap: '20px', flexWrap: 'wrap' }}>
                            <div
                                style={{
                                    padding: '20px',
                                    borderRadius: '15px',
                                    border: '1px solid #ccc',
                                    width: '300px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <img
                                    src={call}
                                    alt="profile-pic"
                                    style={{
                                        width: '30px',
                                        height: '30px',
                                        borderRadius: '50%',
                                        marginRight: '20px',
                                    }}
                                />
                                <div style={{ flex: 1 }}>
                                    <p style={{ color: '#231F20' }}>Mobile number</p>
                                    <p style={{ fontWeight: '600', color: '#231F20' }}>{profileDetails[0]?.MOBILE.trim()}</p>
                                </div>
                            </div>

                            <div
                                style={{
                                    padding: '20px',
                                    borderRadius: '15px',
                                    border: '1px solid #ccc',
                                    width: '300px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',

                                }}
                            >
                                <img
                                    src={mail}
                                    alt="profile-pic"
                                    style={{
                                        width: '30px',
                                        height: '30px',
                                        borderRadius: '50%',
                                        marginRight: '20px',
                                    }}
                                />
                                <div style={{ flex: 1 }}>
                                    <p style={{ color: '#231F20' }}>Email address</p>
                                    <p style={{ fontWeight: '600', color: '#231F20' }}>{profileDetails[0]?.EMAIL.trim()}</p>
                                </div>
                            </div>
                        </div>
                        <p style={{ fontWeight: '600', marginTop: '20px', color: '#231F20' }}>New contact details</p>
                        <div className='mt-3 d-flex' style={{ gap: '20px', flexWrap: 'wrap' }}>
                            <div
                                style={{
                                    padding: '20px',
                                    borderRadius: '15px',
                                    border: '1px solid #ccc',
                                    width: '300px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',

                                }}
                            >
                                <img
                                    src={call}
                                    alt="profile-pic"
                                    style={{
                                        width: '30px',
                                        height: '30px',
                                        borderRadius: '50%',
                                        marginRight: '20px',
                                    }}
                                />
                                <div style={{ flex: 1 }}>
                                    <p style={{ color: '#231F20' }}>Mobile number</p>
                                    <p style={{ fontWeight: '600', color: '#231F20' }}>{pendingData[0]?.mobile}</p>
                                </div>
                            </div>

                            <div
                                style={{
                                    padding: '20px',
                                    borderRadius: '15px',
                                    border: '1px solid #ccc',
                                    width: '300px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',

                                }}
                            >
                                <img
                                    src={mail}
                                    alt="profile-pic"
                                    style={{
                                        width: '30px',
                                        height: '30px',
                                        borderRadius: '50%',
                                        marginRight: '20px',
                                    }}
                                />
                                <div style={{ flex: 1 }}>
                                    <p style={{ color: '#231F20' }}>Email address</p>
                                    <p style={{ fontWeight: '600', color: '#231F20' }}>{pendingData[0]?.email}</p>
                                </div>
                            </div>
                        </div>
                        {
                            pendingData[0]?.status == 4 &&
                            <p style={{ color: 'red', marginTop: '20px' }}>Your change in relation with is rejected due to {pendingData[0]?.rejection?.reason}</p>
                        }
                        {/* <p style={{ color: pendingData[0]?.status_msg == 'Verified' ? 'green' : 'rgb(229, 193, 0)', marginTop: '20px' }}>{pendingData[0]?.status == 0 || pendingData[0]?.status == 4 ? null : pendingData[0]?.status_msg == 'Verified' ? 'Request approved successfully' : 'Request sent and pending approval.'}</p> */}
                        <div style={{ marginTop: '20px' }}>{
                            (pendingData[0]?.validate_status == 0 || (pendingData[0]?.validate_status == 1 && pendingData[0]?.status == 4)) &&
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="checkbox"
                                    id="checkBox"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                    style={{ marginRight: '10px' }}
                                />
                                <p style={{ fontSize: '14px', color: '#231F20' }}>
                                    By clicking on this checkbox, you agree to update the above information in your account
                                </p>
                            </div>
                        }
                            {(pendingData[0]?.validate_status == 0 || (pendingData[0]?.validate_status == 1 && pendingData[0]?.status == 4)) &&
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px', marginBottom: '20px' }}>
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-sm text-11 nominee-page-button-save"
                                        style={{ width: '170px' }}
                                        onClick={() => handclickAddfamily()}
                                        disabled={loading1}
                                    >
                                        {!loading1 ? 'Continoue to E-Sign' : 'Please wait..'}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn  btn-sm text-11 nominee-page-button-save"
                                        style={{ background: 'rgb(241, 91, 77)', color: 'white', marginLeft: '20px' }}
                                        onClick={() => handclickAddfamilyRemove()}
                                        disabled={loading1} >
                                        Decline
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
            }
        </>
    );

};

export default FamilyTable;

