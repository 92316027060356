import React, { useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import _, { isEmpty } from "lodash";
//redux
import { useSelector, useDispatch } from "react-redux";

// actions
import { getAddOnList } from "../../store/addOn/action";
import { addToCart } from "../../store/cart/action";
import { getMyFeaturePlan } from "../../store/myFeaturePlan/action";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
//import "swiper/css/pagination";
import active from "../../assets/images/logo/active.png";
import MyFeature from "../../Components/CustomComponents/Models/addOneFeature/myFeature";
import { getMyPlan } from "../../store/myPlan/action";
import { getMyPlanDuration } from "../../store/myPlanDuration/action";
import { data } from "autoprefixer";
import "./masonry.css";
import axios from "axios";
import Encripted from "./Encripted";

const AddonsSlider = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const client_code = localStorage.getItem("client_id");
  const client_token = localStorage.getItem("client_token");
  const enc = localStorage.getItem("enc");


  const { addOnList } = useSelector((state) => ({
    addOnList: state.AddOn.addOnList,
  }));

  // useEffect(() => {
  //   if (enc) {
  //     axios.post('https://bigul.co/api/decPaymentUrl',{ enc_data: enc })
  //       .then(response => {
  //         // Extract the data_dec from the response
  //         const dataDec = response.data_dec;
          
  //         // Split the data_dec string into individual key-value pairs
  //         const params = new URLSearchParams(dataDec);
  //         console.log(dataDec,'params');
          
  //         // Iterate over each key-value pair and store them in localStorage
  //         for (let [key, value] of params.entries()) {
  //           console.log(key,'key');
  //           console.log(value,'value');
  //           localStorage.setItem(key, value);
  //         }
  //       })
  //       .catch(error => {
  //         console.error("There was an error decoding the enc_data!", error);
  //       });
  //   }
  // }, [enc]);

  // useEffect(() => {
  //   if (enc) {
  //     axios.post('https://bigul.co/api/decPaymentUrl', { enc_data: enc })
  //       .then(response => {
  //         const refreshToken = response.data_dec.match(/refreshToken=([^&]+)/)[1];
  //         const expires = response.data_dec.match(/expires=([^&]+)/)[1];

  //         localStorage.setItem("refreshToken", refreshToken);
  //         localStorage.setItem("expires", expires);
  //       })
  //       .catch(error => {
  //         console.error("There was an error decoding the enc_data!", error);
  //       });
  //   }
  // }, [enc])

  const { myFeaturePlan } = useSelector((state) => ({
    myFeaturePlan: state.MyFeaturePlan.myFeaturePlan,
  }));
  // console.log("gggf",myFeaturePlan)
  const { myPlan } = useSelector((state) => ({
    myPlan: state.MyPlan.myPlan,
  }));
  // console.log("g",myPlan)
  const { myPlanDuration } = useSelector((state) => ({
    myPlanDuration: state.MyPlanDuration.myPlanDuration,
  }));
  useEffect(() => {
    if (client_token) {
      localStorage.setItem("token", client_token);
    }
  }, [])
  ////console.log(myPlanDuration)
  useEffect(() => {
    dispatch(
      getMyPlan({ client_code: client_code, client_token: client_token })
    );
    dispatch(
      getMyPlanDuration({
        client_code: client_code,
        client_token: client_token,
      })
    );
  }, [dispatch, client_code, client_token]);
  /*
  get data
  */
  useEffect(() => {
    dispatch(
      getAddOnList({ client_code: client_code, client_token: client_token })
    );
    dispatch(
      getMyFeaturePlan({ client_code: client_code, client_token: client_token })
    );
  }, [dispatch, client_code, client_token]);

  const charactersLimit = (data, limit) => {
    if (data.length > limit) {
      return data.substring(0, limit).concat(" ...");
    }
    return data;
  };

  const addToCartHandler = (data) => {
    ////console.log(data);
    const item = {
      client_code: client_code,
      client_token: client_token,
      feature_or_plan_id: data.id,
      feature_or_plan: "Feature",
      duration_type: "Monthly",
    };
    dispatch(addToCart(item));
  };

  // const value = myPlan?.plan.map((item)=>item.features.map((fe)=>fe.id))
  const active_features = myFeaturePlan.map((item) => item.feature_id);

  const planValArr =
    myPlan?.plan?.map((item) => item.features.map((fe) => fe.id)).flat() || [];
  let global_type = btoa(client_token);

  // const getParameters = (item) => {
  //   if (item?.parmeters_name_arr === 'NA') {
  //     return '';
  //   }
  
  //   const params = item?.parmeters_name_arr?.map(param => {
  //     let value;
  //     if (param?.enc_value === 1) {
  //       value = btoa(localStorage.getItem(param?.param_value));
  //       // enc_value is 1
  //     } else {
  //       value = localStorage.getItem(param?.param_value);
  //       // enc_value is 0
  //     }
  
  //     return param.parameter + '=' + (value !== null ? value :param?.param_value);
  //   }).join('&');
  
  //   return params;
  // };

  const getParameters = (item) => {
    if (item?.parmeters_name_arr === 'NA') {
      return '';
    }

    const params = item?.parmeters_name_arr?.map(param => {
      let value
      if (param?.enc_value == 1) {
        value = btoa(localStorage.getItem(param?.param_value));

      } else {
        value = localStorage.getItem(param?.param_value);
      }

      return param.parameter + '=' + (value !== null ? value : param?.param_value);
    }).join('&');

    return params;
  };

  return (
    <React.Fragment>
      <Col xxl={12} xl={12}>
        <Row>
          <Col xs={12}>
            <div className="d-flex align-items-center justify-content-between mb-4 mt-4">
              <div>
                <h4 className="card-title mb-0 flex-grow-1 text-6 text-color1">
                  Add-on features
                </h4>
              </div>
              <div className="page-title-right">
                <button
                  className="btn btn-primary btn-sm text-5 addons-button"
                  onClick={() => {
                    navigate("/subscription/features");
                  }}
                >
                  View All
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Carousel
              additionalTransfrom={0}
              arrows={false}
              autoPlay
              autoPlaySpeed={2000}
              centerMode={false}
              className=""
              containerClass="container-with-dots"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite={true}
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 4,
                  partialVisibilityGutter: 40,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 700,
                  },
                  items: 2,
                  partialVisibilityGutter: 30,
                },
                mobile: {
                  breakpoint: {
                    max: 700,
                    min: 0,
                  },
                  items: 1,
                  partialVisibilityGutter: 30,
                },
              }}
              rewind
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >
              {/* {console.log("addOnListaddOnListaddOnListaddOnList", addOnList)} */}
              {/* {console.log("addOnList",addOnList)}
           {console.log("planValArr",planValArr)}
           {console.log("active_features",active_features)} */}


              {addOnList.length > 0 &&
                addOnList.map((item, index) => (
                  <Col className="px-2 h-100 py-3" key={index}>
                    <Card
                      className="ribbon-box right right-left-shadow"
                      style={{ height: "100%" }}
                    >
                      <CardBody className="text-center p-4 h-100 pb-0">
                        {item.flag !== null && (
                          <div className="ribbon ribbon-success ribbon-shape text-5">
                            {item.flag}
                          </div>
                        )}
                        <img
                          className="card-invest-logo align-middle mt-4"
                          src={item.logo || active}
                          alt={item.slug}
                          style={{
                            width: "55px",
                            height: "55px",
                            borderRadius: "50%",
                          }}
                        />
                        <h5
                          className="mb-1 mt-4 text-6 text-color1"
                          style={{ height: "35px" }}
                        >
                          {/* {charactersLimit(item.title, 20)} */}
                          {item.title}
                        </h5>
                        <p
                          className="text-11 text-color1 mt-3 mb-3"
                        // style={{ height: "80px" }}
                        >
                          {charactersLimit(item.short_description, 160)}
                        </p>
                        <div className="">
                          {/* <button
                          className="addons-feature-button-normal"
                          onClick={() => {
                            addToCartHandler(item);
                          }}
                        >
                          Buy Now
                        </button> */}


                          {item.free_status === "YES" ? (
                            item.sso_url ? (
                              <a
                                href={
                                  item.sso_url +
                                  "?" +
                                  getParameters(item)
                                  //   href={
                                  //     item.sso_url +
                                  //     "?" +
                                  //     item.client_code_parameter_name +
                                  //     "=" +
                                  //     btoa(client_code.trim()) +
                                  //     "&" +
                                  //     item.token_parameter_name +
                                  //     "=" +
                                  //  (   item.login_source == 'Middleware' ? global_type = client_token : btoa(symphony_token))
                                }
                                target="_blank"
                                login_source={item.login_source}
                                className="Access-btn"
                              >
                                <button
                                  className="text-4 btn btn-primary addons-feature-button-normal Access-btn"
                                  disabled
                                  onClick={() => {
                                    addToCartHandler(item);
                                  }}
                                  style={{
                                    backgroundColor: "#00ffc1",
                                    color: "black",
                                    fontSize: "13px",
                                  }}
                                >
                                  Access Now
                                </button>
                              </a>
                            ) : (
                              <button
                                className="text-4 btn btn-primary addons-feature-button-normal"
                                disabled
                                onClick={() => {
                                  addToCartHandler(item);
                                }}
                              >
                                Subscribed
                              </button>
                            )
                          ) : planValArr?.includes(item?.id) ||
                            active_features?.includes(item?.id) ? (
                            item.sso_url ? (
                              <a
                                href={
                                  item.sso_url +
                                  "?" +
                                  getParameters(item)
                                  // item.client_code_parameter_name +
                                  // "=" +
                                  // btoa(client_code.trim()) +
                                  // "&" +
                                  // item.token_parameter_name +
                                  // "=" +
                                  // (   item.login_source == 'Middleware' ? global_type = client_token : btoa(symphony_token))
                                }
                                login_source={item.login_source}
                                target="_blank"
                              >
                                <button
                                  className="text-4 btn btn-primary addons-feature-button-normal "
                                  disabled
                                  onClick={() => {
                                    addToCartHandler(item);
                                  }}
                                  style={{
                                    backgroundColor: "#00ffc1",
                                    color: "black",
                                    fontSize: "13px",
                                  }}
                                >
                                  Access Now
                                </button>
                              </a>
                            ) : (
                              <button
                                className="text-4 btn btn-primary addons-feature-button-normal"
                                disabled
                                onClick={() => {
                                  addToCartHandler(item);
                                }}
                              >
                                Subscribed
                              </button>
                            )
                          ) : (
                            <MyFeature data={item.id} btnText="Subscribe" />
                          )}

                          {/* {!isEmpty(match) || !isEmpty(match2) ? (
                          <button
                            type="button"
                            className="addons-feature-button-normal-dis"
                            disabled
                          >
                            Subscribed
                          </button>
                        ) : (
                          <MyFeature data={item.id} btnText="Subscribe" />
                        )} */}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
            </Carousel>
          </Col>
        </Row>
      </Col>
    </React.Fragment>
  );
};

export default AddonsSlider;
