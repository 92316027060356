import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
//redux
import { useSelector, useDispatch } from "react-redux";
import { Card, CardHeader, Col } from "reactstrap";
//action
import {
  getMarginOverview,
  getBankDetails,
} from "../../store/marginOverview/action";
import { getCollateral } from "../../store/Collateral/action";
import {
  getCashbalance_new,
  getCashbalance_new1,
  getLedgerApi,
  getPayout_new,
  latestUpdateOnApi,
  SymphonyWithdrawalBalance
} from "../../helpers/fakebackend_helper";
// image
import margin_overview_bottom from "../../assets/images/logo/margin_overview_bottom.png";
import "./masonry.css";
import WithdrawFunds from "../../Components/CustomComponents/Models/withDraw/WithdrawFunds";
import Encripted from "./Encripted";
import loaderImage from "../../assets/images/Loader.png";
import tick from "../../assets/images/tick.png";
import { LOGOUT_APP, Payment_url, Payment_url_Bonanza, redirect_app } from "../../helpers/url_helper";
import EncPledge from "./EncPledge";
import { toast } from "react-toastify";
import axios from "axios";




const MarginOverview = (props) => {
  var advancedFormat = require("dayjs/plugin/advancedFormat");
  dayjs.extend(advancedFormat);
  const [isDownload, setIsDownload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState([]);
  const [updateData, setUpdateData] = useState("");
  const { generatedKey, encryptKey } = props;
  const [TotalValue, setTotalValue] = useState(0);
  const [TotalValue1, setTotalValue1] = useState(0);
  const [openModal, setOpenModal] = useState(false)
  const [openModal1, setOpenModal1] = useState(false)
  const dispatch = useDispatch();
  const startDate = new Date();
  const endDate = new Date();
  startDate.setDate(startDate.getDate() - 7);

  const [selectDate, setSelectDate] = useState({ date: [startDate, endDate] });
  const openState = {
    CUpdate: true,
    otp1: false,
    done: false,
  };
  const client_code = localStorage.getItem("client_id");
  useEffect(() => {
    const fetchData = async () => {
      await latestUpdateOnApi({ clientCode: client_code })
        .then((response) => {
          // //console.log(response);
          setUpdateData(response.createdAt);
        })
        .catch((err) => {
          ////console.log(err);
        });
    };


    const timer = setTimeout(() => {
      fetchData();
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (client_type == 'Bigul') {
      setLoading(true)
      getCashbalance_new()
        .then((response) => {
          if (response?.status) {
            getPayout_new()
              .then((response1) => {
                const check = parseFloat(response?.total_amount);
                const check1 = parseFloat(response?.bod_amount);
                if (response1?.status) {
                  const formattedPayout = check1.toFixed(2) - parseFloat(response1?.data).toFixed(2);
                  setTotalValue(check)
                  setTotalValue1(formattedPayout)
                  setLoading(false)
                }
                else {
                  setLoading(false)

                }
              }).catch((err) => {
                setLoading(false)
                toast.error('something went wrong')

              });
          }
          else {

            setLoading(false)
          }
        })
        .catch((err) => {
          setLoading(false)
          toast.error('something went wrong')
          setWithdrawalBalance(0);

        });
    }
    else {
      setLoading(true)
      getCashbalance_new1()
        .then((response) => {
          if (response?.status) {
            getPayout_new()
              .then((response1) => {
                // const check = parseFloat(response?.total_amount);
                const check1 = parseFloat(response?.data.bod_amount);
                if (response1?.status) {
                  const formattedPayout = check1.toFixed(2) - parseFloat(response1?.data).toFixed(2);
                  setTotalValue(check1)
                  setTotalValue1(formattedPayout)
                  setLoading(false)
                }
                else {
                  setLoading(false)

                }
              }).catch((err) => {
                setLoading(false)
                toast.error('something went wrong')

              });
          }
          else {

            setLoading(false)
          }
        })
        .catch((err) => {
          setLoading(false)
          toast.error('something went wrong')
          setWithdrawalBalance(0);

        });
    }
  }, [generatedKey, encryptKey]);

  useEffect(() => {
    dispatch(getMarginOverview({ apiKey: generatedKey, code: encryptKey }));
    dispatch(getBankDetails({ apiKey: generatedKey, code: encryptKey }));
    dispatch(getCollateral({ apiKey: generatedKey, code: encryptKey }));
  }, [dispatch, generatedKey, encryptKey]);

  const client_type = localStorage.getItem("client_type");


  const { availableMargin } = useSelector((state) => ({
    availableMargin: state.MarginOverview,
  }));

  const { Collateral } = useSelector((state) => ({
    Collateral: state.Collateral.Collateral,
  }));



  const addFundHandler = () => {

    const paylod = {
      'ClientCode': client_code,
      redirectUrl: redirect_app,
      SubscriptionPlan: 'client_dashboard',
    }

    window.open(
      (client_type == 'Bigul' ? Payment_url : Payment_url_Bonanza)
      + `?enc=${Encripted([paylod])}`,
      "_blank"
    );
  };

  function convertValue(value) {


    // Check if the value is negative
    if (value < 0) {
      // If negative, convert it to positive
      return Math.abs(value);
    } else {
      // If positive, convert it to negative
      return -value;
    }
  }
  let convertedValue = convertValue(availableMargin?.availableMargin[0]?.Final1);

  const myArray = [];
  for (let i = 0; i < file.length; i += 1) {
    const childArray = file[i];
    const obj = {
      Name: childArray.LNarrat.replace("#", ""),
      Date: childArray.Ldate,
      Amount: childArray.Damount,
      Balance: childArray.Camount,
    };
    myArray.push(obj);
  }

  const userHeader = ["Name", "Date", "Amount", "Balance"];
  myArray.unshift(userHeader);

  const array2 = [];
  myArray.forEach((el) => {
    const propertyValues = Object.values(el);
    array2.push(propertyValues);
  });
  // //console.log(array2);
  const csvContent = `data:text/csv;charset=utf-8,
		 ${array2.map((e) => e.join(",")).join("\n")}`;
  // //console.log(array2);
  // //console.log(csvContent);
  // const encodedUri = encodeURI(csvContent);
  // //console.log(encodedUri)
  const link = document.createElement("a");
  link.setAttribute("href", csvContent);
  link.setAttribute("download", `Ledger`);
  document.body.appendChild(link);
  useEffect(() => {
    if (isDownload === true) {
      link.click();
    }
    setIsDownload(false);
  }, [isDownload]);

  const diff_minutes = (dt2, dt1) => {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  };

  const openInNewWindow = () => {
    window.open("https://bigul.co/pledge-details?enc_data=" + EncPledge(), "_blank");
  };



  const handlesubmit = async () => {
    // 

    try {
      // Define headers for the POST request
      const headers = {
        "token": props?.optOut,  // Example of Authorization header
        // Any custom header
      };

      // Define the body data (payload) for the POST request
      const postData = {
        client_code: client_code
      };


      const response = await axios.post(
        props?.optOut1 ?
          'https://bigul.co/api/retrive-client-from-optout' :
          "https://bigul.co/api/add-client-optout",
        postData,
        { headers: headers }
      );
      if (response?.status) {
        setOpenModal(false)
        setOpenModal1(true)
        // window.location.reload();
      }
      else {
        toast.error(response?.msg)
      }

      // Set loading to false once data is received
    } catch (err) {
      toast.error(err?.msg || 'something went wrong')
    }
  }
  const CollateralValues = [];
  const CollateralNew = Collateral.filter((el) => {
    if (el.CollateralQuantity > 0) {
      CollateralValues.push(
        el.CollateralQuantity * el.mktrate -
        (el.CollateralQuantity * el.mktrate * el.Haircut) / 100
      );
    }
    return el.CollateralQuantity > 0;
  });
  return (
    <React.Fragment>
      {
        openModal &&
        <div
          style={
            {
              position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000,

            }
          } >
          <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px', maxWidth: '400px', width: '100%', textAlign: 'center', }} >
            <div style={{ marginTop: '10px' }}>
              <p>{props?.optOut1?'Please note that the funds from the sale of stocks will be allocated to your trading account in real-time within a specified maximum limit.':'Please note that by opting out, the funds from the sale of stocks will no longer be allocated to your trading account in real-time and will follow the standard settlement process'}</p>
              <button style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer', border: 'none', backgroundColor: 'gray', color: 'white', borderRadius: '5px', marginTop: '20px', }}
                onClick={() => { setOpenModal(false) }}

              >

                Cancel

              </button>
              <button

                style={{
                  padding: '10px 20px', fontSize: '16px', cursor: 'pointer', border: 'none', backgroundColor: '#5367FC', color: 'white', borderRadius: '5px', marginTop: '20px', marginLeft: '20px'

                }}

                onClick={() => { handlesubmit() }}

              >

                Submit

              </button>
            </div>

          </div>

        </div>
      }
      {
        openModal1 &&
        <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '5px',
            maxWidth: '400px',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {/* Image at the center */}
          <div style={{ marginBottom: '20px' }}>
            <img
              src={tick} // Replace with your image URL
              alt="centered"
              style={{
                width: '100px', // Image size
                height: '100px', // Image size
                objectFit: 'cover', // To make sure image doesn't stretch
                borderRadius: '50%', // Optional: if you want a circular image
                margin: '0 auto', // Centering the image
              }}
            />
          </div>
      
          {/* Content */}
          <div style={{ marginTop: '10px' }}>
            <p style={{fontSize:'18px',textAlign:'center',fontWeight:'500'}}>{props?.optOut1?'Successfully opted in for instant margin against sell':'Successfully opted out from instant margin against sell'}</p>
          </div>
      
          {/* Button */}
          <div style={{ marginTop: '20px' }}>
            <button
              style={{
                padding: '10px 20px',
                fontSize: '16px',
                cursor: 'pointer',
                border: 'none',
                backgroundColor: '#5367FC',
                color: 'white',
                borderRadius: '5px',
                marginTop: '20px',
              }}
              onClick={() => {
                window.location.reload();
              }}
            >
              Go to Dashboard
            </button>
          </div>
        </div>
      </div>
      
        
      }
      <Col
        xxl={3}
        xl={3}
        lg={6}
        md={6}
        sm={6}
        className="spacing-remove-demounting dashboard-widget-bottom-margin2"
      >
        <Card className="m-0">
          <CardHeader className="border-0 d-flex pb-0 align-items-center">
            <div className="flex-grow-1">
              <h4 className="text-6 text-color1 mb-1">Cash Balance</h4>

            </div>
            <div className="flex-shrink-0">
              <div className="card-overview-div-logo rounded-circle align-middle">
                <Link
                  className="card-overview-div-logo-button"
                  //onClick={downloadHandler}
                  to="/overview"
                >
                  <i
                    className={"align-middle " + "ri-arrow-right-fill"}
                    style={{ color: "#FFF" }}
                  ></i>
                </Link>
              </div>
            </div>
          </CardHeader>
          <div className="card-body px-3 py-0">
            <h4
              className={`text-8 text-color5  mb-1 flex-grow-1 ${(TotalValue > 0 ? "text-color6" : "text-color11")
                }`}
            >{
                loading ?
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={loaderImage} className="custom-loader" style={{ height: "20px", }} />
                  </div>
                  : '₹' + TotalValue?.toFixed(2)
              }

            </h4>
            <h4 className="text-1 mb-0 flex-grow-1 text-color1">
              {updateData !== "" &&
                `As on  ${dayjs(updateData).format("DD MMM YYYY")}`}
              {/* last updated {diff_minutes(new Date, new Date(updateData))} */}
            </h4>
          </div>
          <div className="card-body p-3">
            <div className="pt-1">
              <button
                type="button"
                className="btn btn-success text-4 w-100 mb-3 margin-overview-button2"
                style={{ borderRadius: "5px", fontSize: '15px' }}
                onClick={addFundHandler}
              >
                <i
                  className={"align-middle me-2 fs-5 " + "ri-add-circle-line"}
                ></i>
                Add Fund
              </button>
              <Link
                type="button"
                className="btn btn-success text-4 w-100 margin-overview-button2  add-Collateral-btn "
                style={{
                  borderRadius: "5px",
                  cursor: "notAllowed",
                  backgroundColor: "#5367fc",
                  borderColor: "#5367fc",
                }}
                onClick={openInNewWindow}

              >
                <i
                  className={"align-middle me-2 fs-5 " + "ri-add-circle-line"}
                ></i>
                Add Collateral
              </Link>
              {/* <Link
                type="button"
                className="btn btn-success text-4 w-100 margin-overview-button2  add-Collateral-btn mt-3 "
                style={{
                  borderRadius: "5px",
                  cursor: "notAllowed",
                  backgroundColor: "#5367fc",
                  borderColor: "#5367fc",

                }}
                onClick={() => { setOpenModal(true) }}

              >

                Opt-{props?.optOut1 ? 'In' : 'out'}
              </Link> */}

              {TotalValue > 0 &&
                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                  <WithdrawFunds
                    data={availableMargin}
                    openState={openState}
                    wAmount={TotalValue1} // balance pending he static lagaya he abhi
                  />
                </div>
              }
            </div>
          </div>
          <div className="card-overview-div-image">
            {
              client_type == "Bonanza" ?
                null
                :
                <img className="card-overview-image" src={margin_overview_bottom} />

            }
          </div>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default MarginOverview;
